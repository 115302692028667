@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url("./assets/images/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
